import React, { useState } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"

import { Below } from "./CustomComponents"

const ServiceModule = ({ children, serviceTitle = "Title Here" }) => {
  const [displayText, setDisplayText] = useState(false)

  const Module = styled.div`
    margin-bottom: 50px;

    ${Below.med`
    margin-bottom: 25px;

    
  `}
  `

  const ModuleTitle = styled.h2`
    text-align: left;

    :hover {
      cursor: pointer;
    }

    ${Below.med`
    font-size: 40px;
    line-height: 40px;
    
  `}
  `

  return (
    <Module>
      <ModuleTitle
        onClick={() => setDisplayText(prevValue => !prevValue)}
        role="button"
      >
        {" "}
        {serviceTitle}{" "}
        <span style={{ color: "var(--color1)" }}>
          {displayText ? "-" : "+"}
        </span>
      </ModuleTitle>
      <AnimatePresence>
        {displayText && (
          <motion.div
            style={{ overflow: "hidden" }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 1 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Module>
  )
}

export default ServiceModule
