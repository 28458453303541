import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, Link, graphql } from "gatsby"

import { Below } from "./CustomComponents"

const SmallLogoFullStyled = styled.div`
  display: grid;
  max-width: 300px;
  z-index: 101;
  position: relative;
  /* top: 0;
  left: 0; */

  ${Below.med`
    padding-top: 70px;
    max-width: none;
  `}
`

const HomeLink = styled(Link)`
  ${Below.med`
  justify-self: center;
  width: 300px;
  `}
`

const SmallLogoFull = () => {
  const data = useStaticQuery(graphql`
    query SmallLogoFullQuery {
      allFile(filter: { name: { eq: "BorealisLogo_full" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const logo = data.allFile.edges[0].node.childImageSharp

  return (
    <SmallLogoFullStyled>
      <HomeLink to="/">
        <Img fluid={logo.fluid} />
      </HomeLink>
    </SmallLogoFullStyled>
  )
}

export default SmallLogoFull
