import React from "react"
import styled from "styled-components"

const Card = styled.div`
  border: 1px solid white;
  width: 300px;
  height: 150px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: white;
    color: var(--color1);
  }
`

const ServiceCard = ({ service = "A service" }) => {
  return <Card>{service}</Card>
}

export default ServiceCard
