import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SmallLogoFull from "../components/SmallLogoFull"
import styled from "styled-components"

import { TextEmph, Below } from "../components/CustomComponents"
import SubPageContent from "../components/SubPageContent"
import ServiceCard from "../components/ServiceCard"
import ServiceModule from "../components/ServiceModule"

const ServicesContent = styled.div`
  width: 80%;
  font-size: 1.5em;
  line-height: 1.25em;

  p {
    margin-bottom: 20px;
    line-height: 1.1em;
  }
`

const TagLine = styled.p`
  text-align: center;
  color: var(--color1);
  font-weight: 500;

  ${Below.med`
    font-size: 30px;
  `}
`

const CardContainer = styled.div`
  display: grid;
  justify-items: center;
  row-gap: 20px;
  margin-top: 25px;
`

// const TextEmph = styled.span`
//   color: var(--color1);
//   font-weight: 400;
// `

const ServiceModuleContainer = styled.div`
  margin-top: 50px;

  p {
    ${Below.med`
    font-size: 25px;
    text-align: center; 
  `}
  }
`

const Services = () => {
  const [customDesign, setCustomDesign] = useState(false)
  return (
    <Layout>
      <Helmet>
        <title>Borealis | Services</title>
      </Helmet>
      <SmallLogoFull />
      <SubPageContent title="Services">
        <ServicesContent>
          <TagLine>
            Whether you need a simple, clean landing page
            <br /> or a complex web platform with custom requirements
            <br /> – we’d love to help.
          </TagLine>
          <ServiceModuleContainer>
            <ServiceModule serviceTitle="Custom Website Development">
              <p>
                Working in collaboration with our clients, we focus on creating
                custom websites that are{" "}
                <TextEmph>
                  beautiful, functional, and always user-friendly
                </TextEmph>
                .
              </p>
              <p>
                Using cutting-edge technology developed by leading tech
                companies, our products are created to be{" "}
                <TextEmph>
                  easily updated and managed by clients themselves
                </TextEmph>
                .
              </p>
              <p>
                Whether you’re looking to sell your products, reach out to
                stakeholders, or even just move registration for your sports
                club online – our sites are custom-built to be the right fit for
                your business needs.
              </p>
            </ServiceModule>
            <ServiceModule serviceTitle="E-Commerce">
              <p>
                Beginning with a comprehensive understanding of e-commerce
                usability, we offer a range of up-to-date e-commerce design and
                development services to{" "}
                <TextEmph>complement and grow your business</TextEmph>.
              </p>
              <p>
                Offering <TextEmph>smooth user experiences</TextEmph> and simple
                conversion pathways, our designs and applications will create a
                seamless process for customers while effectively driving your
                online sales.{" "}
              </p>
            </ServiceModule>
            <ServiceModule serviceTitle="Additional Services">
              <p>
                <TextEmph>
                  Need help with post-launch support and maintenance?
                </TextEmph>{" "}
                <br />
                We love to see how our designs evolve and grow, and our team
                would be happy to continue working on new features for your
                business on an ongoing basis. Plans for maintenance and support
                are available in both hourly and package rates.
              </p>
              <p>
                <TextEmph>Curious about custom applications?</TextEmph>
                <br /> Whether you’re an organization looking to add new
                functionality to your website, or in need of a better way for
                clients to pay their dues, our team can help design and develop
                customized applications that meet your specific requirements.
              </p>
              <p>
                <TextEmph>Looking for other creative services?</TextEmph>
                <br /> While our focus is primarily website design and
                development, we often join forces with like-minded{" "}
                <TextEmph>graphic designers</TextEmph>,{" "}
                <TextEmph>copywriters</TextEmph> and{" "}
                <TextEmph>creative agencies</TextEmph> to help streamline the
                brand-building process. Let us know if you’re looking for a
                one-stop shop.
              </p>
            </ServiceModule>
          </ServiceModuleContainer>
        </ServicesContent>
      </SubPageContent>
    </Layout>
  )
}

export default Services
