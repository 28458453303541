import React from "react"
import styled from "styled-components"

import { Below } from "./CustomComponents"

const Content = styled.div`
  display: grid;
  justify-items: center;
  padding-bottom: 25px;
  margin-top: 25px;

  h1 {
    ${Below.med`
    font-size: 65px;
  text-align: center;

    
  `}
  }
`

const SubPageContent = ({ children, title = "Some Page" }) => {
  return (
    <Content>
      <h1>{title}</h1>
      {children}
    </Content>
  )
}

export default SubPageContent
